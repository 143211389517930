export interface NewConnection {
  distributorid: string;
  issuerid: string;
  issuersalescontactid: string;
  template: string;
}

export enum ConnectionStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  WITHDRAWN = 'WITHDRAWN',
}

export interface Connection {
  id: string;
  distributorid: string;
  issuerid: string;
  issuersalescontactid: string;
  createdat: string;
  template?: string;
  lastactivityat: string;
  status: ConnectionStatus;
  inspreadsheet: string;
  issuername: string;
  issuercontactname: string;
  distributorname: string;
  expdate?: string;
  lastrenewaldate?: string;
  renewalresponse?: string;
  distrenewalresponse?: string;
  issuercontactemail: string;
  chasestartedat?: string | Date;
}

export interface ExtraTableConnectionFields {
  issuerName: string;
  issuerContact: string;
  type?: string;
}

export interface ConnectionStatusUpdate {
  status: ConnectionStatus;
}

export type TableConnection = Connection & ExtraTableConnectionFields;
