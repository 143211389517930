import { Identified } from '../../model/identified';
import { DDQStatus } from './ddq';
import { Contact } from './contact';

export interface RequestData {
  createdby: string;
  createdat: string;
  type: RequestType;
  status: RequestStatus;
  lockeduntil?: bigint;
  lockedby?: string;
  details: any;
  chasestartedat?: string;
}

export interface RequestEvent {
  author: string;
  date: string;
  type: EventType;
}

export enum RequestType {
  KYD = 'KYD',
  KYDDaily = 'KYD - Screening - Daily Only',
  KYDLookback = 'KYD - Screening - Lookback Only',
  DA = 'DA Drafting',
  KYC = 'KYC',
}

export const RequestTypeAlt = [
  {
    id: 'KYD',
    name: 'KYD',
  },
  {
    id: 'KSL',
    name: 'KSL',
  },
  {
    id: 'KSD',
    name: 'KSD',
  },
  {
    id: 'KYC',
    name: 'KYC',
  },
  {
    id: 'DAD',
    name: 'DAD',
  },
  {
    id: 'ADF',
    name: 'ADF',
  },
];

export const OffPlatformRequestType = [
  {
    id: 'KSL',
    name: 'KSL',
  },
  {
    id: 'KSD',
    name: 'KSD',
  },
  {
    id: 'KYC',
    name: 'KYC',
  },
];

export enum RequestStatus {
  pending = 'PENDING',
  active = 'ACTIVE',
  signed = 'SIGNOFF',
  completed = 'COMPLETED',
}

export enum RequestTableStatus {
  issuer = 'Issuer to Action',
  distributor = 'Distributor to Action',
  inspire = 'Inspire to Action',
  complete = 'Completed',
  approval = 'Approval Required',
}

export enum EventType {
  created = 'CREATED',
  sent = 'SENT',
  started = 'STARTED',
}

export enum Region {
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMER = 'AMER',
}

export interface RequestTable {
  request: Request;
  questionnaireStatus?: DDQStatus;
  created: string;
  issuerName: string;
  distributorName: string;
  lei: string;
  contact: [Contact];
  type: RequestType;
  comms: string;
  ddqProgress: string;
  status: RequestTableStatus;
  distributorKey: string;
  region?: string;
  template?: string;
  chaseTimer?: number;
  signed?: string;
  expireOn?: string;
}

export interface TableID {
  id: string;
}

export type Request = RequestData & TableID;

export interface Counts {
  all: number;
  inspire: number;
  issuer: number;
  distributor: number;
  completed: number;
  offPlatform: number;
}
