import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HOSTNAME } from '../util/hostname';
import { AuthService, UserSession } from '../auth/services/auth.service';
import { Observable } from 'rxjs';

let API_ROOT = 'https://api.' + HOSTNAME + '/';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private session: UserSession;
  isLocal: boolean = false;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    // default 4200 port using dev db in aws cloud, set port 9000 'ng serve --port 9000' to use local db
    if (window.location.hostname === 'localhost' && window.location.port === '9000') {
      this.isLocal = true;
      API_ROOT = `http://localhost`;
    }
    authService.onUserSession.subscribe((session: UserSession) => {
      this.session = session;
    });
  }

  get<T>(path: string): Observable<T> {
    if (this.isLocal && path.startsWith('auth/')) {
      // when running locally, still use remote dev auth environment
      return this.httpClient.get<T>(`https://api.dev.hub.inspire.direct/` + path, this.getOptions());
    } else {
      return this.httpClient.get<T>(API_ROOT + this.getPath(path), this.getOptions());
    }
  }

  delete<T>(path: string): Observable<T> {
    if (this.isLocal && path.startsWith('auth/')) {
      // when running locally, still use remote dev auth environment
      return this.httpClient.delete<T>(`https://api.dev.hub.inspire.direct/` + path, this.getOptions());
    } else return this.httpClient.delete<T>(API_ROOT + this.getPath(path), this.getOptions());
  }

  post<T, R>(path: string, payload: T): Observable<R> {
    if (this.isLocal && path.startsWith('auth/')) {
      // when running locally, still use remote dev auth environment
      return this.httpClient.post<R>(`https://api.dev.hub.inspire.direct/` + path, payload, this.getOptions());
    } else return this.httpClient.post<R>(API_ROOT + this.getPath(path), payload, this.getOptions());
  }

  put<T, R>(path: string, payload: T): Observable<R> {
    if (this.isLocal && path.startsWith('auth/')) {
      // when running locally, still use remote dev auth environment
      return this.httpClient.put<R>(`https://api.dev.hub.inspire.direct/` + path, payload, this.getOptions());
    } else return this.httpClient.put<R>(API_ROOT + this.getPath(path), payload, this.getOptions());
  }

  patch<T, R>(path: string, payload: T): Observable<R> {
    if (this.isLocal && path.startsWith('auth/')) {
      // when running locally, still use remote dev auth environment
      return this.httpClient.patch<R>(`https://api.dev.hub.inspire.direct/` + path, payload, this.getOptions());
    } else return this.httpClient.patch<R>(API_ROOT + this.getPath(path), payload, this.getOptions());
  }

  public getOptions() {
    return {
      headers: {
        Authorization: 'Bearer ' + this.session?.accessToken?.jwtToken,
        'ID-TOKEN': this.session?.idToken?.jwtToken,
        Accept: 'application/json',
      },
    };
  }

  getPath(path) {
    return this.isLocal ? (path.startsWith('ddq/') ? ':4010/' + path.slice(4) : path) : path;
  }
}
