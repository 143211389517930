import { RequestData } from './request';
import { DDQStatus } from './ddq';
import { Contact } from './contact';

interface KYDRequestFormData {
  id?: string;
  draftVersion?: string;
  status: DDQStatus;
  progress?: number;
  liveProgress?: number;
}

interface KYDRequestDistributorData {
  name: string;
  contact: [string];
  signedOffTimeStamp?: string;
}

interface KYDRequestTemplateData {
  name?: string;
  region?: string;
  version?: string[];
}

interface KYDRequestIssuerData {
  name: string;
}

export interface KYDRequestSummary {
  requestKey: string;
  issuerName: string;
  distributorKey: string;
  distributorName: string;
  distributorAddress: string;
  contact: [Contact];
  ddqType: string;
}

export interface KYDRequestData extends RequestData {
  form: KYDRequestFormData;
  distributor: KYDRequestDistributorData;
  template: KYDRequestTemplateData;
  requester: KYDRequestIssuerData;
  lei?: string;
  distributorName?: string;
}

export interface kydRequestDetails {
  form: KYDRequestFormData;
  distributor: KYDRequestDistributorData;
  template: KYDRequestTemplateData;
  requester: KYDRequestIssuerData;
}

export enum KYDRequestActions {
  enrich = 'Enrich DDQ',
  publish = 'Publish to Distributor',
  view = 'View DDQ',
  signoff = 'Request Sign Off',
  download = 'Download DDQ',
  assign = 'Assign to Issuer',
  reopen = 'Re-open',
  drafting = 'Drafting',
  completed = 'Mark as Completed',
  progress100 = 'Progress to 100%',
  manuallysignoff = 'Sign Off Manually',
}
