import { Injectable } from '@angular/core';
import { Observable, of, pipe, Subject } from 'rxjs';
import { ConnectionService } from './connection.service';
import { Connection, ConnectionStatus, TableConnection } from '../model/connection';
import { filter, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { ReturnedError } from '../model/error';
import { IssuersService } from './issuers.service';
import { Issuer } from '../model/issuer';
import { IssuerSalesContact } from '../model/issuer-sales-contact';
import { DistributorsService } from './distributors.service';
import { CompanyInfo } from '../model/distributor';
import { KydRequestService } from './kyd-request.service';
import { Request } from '../model/request';
import { CacheService } from './cache.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  refreshSubject: Subject<void> = new Subject<void>();
  loadingSubject: Subject<string> = new Subject<string>();

  profileInProgress;
  availableDistributors: any[];
  availableTemplates: any[];
  selectedDistributor;
  selectedTemplate;

  formatConnectionsForUI = () =>
    pipe(
      switchMap((connectionsList: Connection[]) => {
        return of(...connectionsList).pipe(
          mergeMap((connection: Connection) =>
            this.issuersService.getIssuer(connection.issuerid).pipe(
              mergeMap((issuer: Issuer) =>
                this.issuersService.getIssuerSalesContact(connection.issuersalescontactid).pipe(
                  // filter((issuerSalesContact: IssuerSalesContact) => issuerSalesContact !== null),
                  map((issuerSalesContact: IssuerSalesContact) => {
                    if (issuerSalesContact) {
                      return Object.assign(connection, {
                        issuerName: issuer.name,
                        issuerContact: issuerSalesContact.name,
                      }) as TableConnection;
                    } else {
                      return Object.assign(connection, {
                        issuerName: issuer.name,
                        issuerContact: '[de-registered user]',
                      }) as TableConnection;
                    }
                  })
                )
              )
            )
          ),
          toArray()
        );
      })
    );

  constructor(
    private connectionService: ConnectionService,
    private issuersService: IssuersService,
    private distributorsService: DistributorsService,
    private kydRequestService: KydRequestService,
    private cacheService: CacheService,
    private currentUserService: CurrentUserService,
    private apiService: ApiService
  ) {}

  fetchCompanyInfo(distributorId): Observable<CompanyInfo> {
    return this.distributorsService.getMyCompanyInformation(distributorId);
  }

  fetchProfilesByDistributorId(distributorId): Observable<any[]> {
    return this.apiService.get<any>(`ddq/request/profiles/${distributorId}`);
  }

  fetchProfileByDistributorInfo(distributorId, type, template): Observable<Request> {
    return this.apiService.get<any>(`ddq/request/profile/${distributorId}/${type}/${template}`);
  }

  fetchAllRequestsData(distributorId): Observable<any> {
    return this.connectionService.getMyPendingConnections(distributorId).pipe(this.formatConnectionsForUI());
  }

  fetchAllContributors(requestId): Observable<any> {
    return this.kydRequestService.getMyContributors(requestId);
  }

  fetchAllConnections(distributorId): Observable<TableConnection[]> {
    return this.connectionService.getMyNonPendingConnections(distributorId).pipe(this.formatConnectionsForUI());
  }

  acceptConnection(connectionID): Observable<any> {
    return this.connectionService.updateConnectionStatus(connectionID, ConnectionStatus.ACTIVE);
  }

  declineConnection(connectionID): Observable<Connection | ReturnedError> {
    return this.connectionService.updateConnectionStatus(connectionID, ConnectionStatus.DECLINED);
  }

  checkConnectionCurrentlyPending(connectionID) {
    return new Promise((resolve) => {
      this.connectionService.getConnection(connectionID).subscribe((connection) => {
        if (connection.hasOwnProperty('id')) {
          if ((connection as Connection).status === ConnectionStatus.PENDING) {
            resolve({
              response: true,
            });
          } else {
            resolve({
              response: false,
              value: (connection as Connection).status,
            });
          }
        } else {
          resolve({
            response: false,
          });
        }
      });
    });
  }

  async getFirstProfile() {
    if (!this.cacheService.getData().profileInfo) {
      await new Promise((resolve, reject) => {
        this.currentUserService.currentUser.subscribe(
          (result) => {
            let currentUserEmail = result.email;
            this.distributorsService.getDistributorsByEmail(currentUserEmail).subscribe((distributors) => {
              this.availableDistributors = distributors;
              this.selectedDistributor =
                this.availableDistributors.find((x) => x.distributorId === result.distributors[0]) ||
                this.availableDistributors[0];

              this.fetchProfilesByDistributorId(this.selectedDistributor.distributorId).subscribe(
                (connections) => {
                  const templates = connections.map((x) => {
                    if (x.type === 'KYD') {
                      return {
                        type: x.type,
                        template: x.template,
                        description: `Due Diligence - ${x.template}`,
                        requestId: x.id,
                      };
                    } else if (x.template === 'ADF') {
                      return {
                        type: x.type,
                        template: x.template,
                        description: 'Annual Distributor Feedback',
                        requestId: x.id,
                      };
                    } else
                      return {
                        type: x.type,
                        template: x.template,
                        description: `${x.type} - ${x.template}`,
                        requestId: x.id,
                      };
                  });

                  this.availableTemplates = [...templates];
                  this.selectedTemplate = this.availableTemplates[0];

                  this.cacheService.addData({
                    profileInfo: {
                      distributors: this.availableDistributors,
                      currentProfile: {
                        currentDistributor: this.selectedDistributor,
                        currentTemplate: this.selectedTemplate,
                        templates: this.availableTemplates,
                      },
                    },
                  });
                  resolve(true);
                },
                (error) => {
                  console.log(error);
                }
              );
            });
          },
          (error) => {
            console.log(error);
          }
        );
      });
      return {
        distributors: this.availableDistributors,
        currentProfile: {
          currentDistributor: this.selectedDistributor,
          currentTemplate: this.selectedTemplate,
          templates: this.availableTemplates,
        },
      };
    } else {
      return this.cacheService.getData().profileInfo;
    }
  }

  async selectDistributor(distributorId) {
    let previousProfileInfo;
    if (!this.cacheService.getData().profileInfo) {
      previousProfileInfo = await this.getFirstProfile();
    } else {
      previousProfileInfo = this.cacheService.getData().profileInfo;
    }
    return this.fetchProfilesByDistributorId(distributorId)
      .toPromise()
      .then((connections) => {
        const templates = connections.map((x) => {
          if (x.type === 'KYD') {
            return {
              type: x.type,
              template: x.template,
              description: `Due Diligence - ${x.template}`,
              requestId: x.id,
            };
          } else if (x.template === 'ADF') {
            return {
              type: x.type,
              template: x.template,
              description: 'Annual Distributor Feedback',
              requestId: x.id,
            };
          } else
            return {
              type: x.type,
              template: x.template,
              description: `${x.type} - ${x.template}`,
              requestId: x.id,
            };
        });

        this.availableTemplates = [...templates];
        this.selectedTemplate = this.availableTemplates[0];

        const updatedProfileInfo = {
          distributors: previousProfileInfo.distributors,
          currentProfile: {
            currentDistributor: previousProfileInfo.distributors.find((x) => x.distributorId === distributorId),
            currentTemplate: this.selectedTemplate,
            templates: this.availableTemplates,
          },
        };

        this.cacheService.removeEntry('profileInfo'); // must delete it first!
        this.cacheService.addData({
          profileInfo: updatedProfileInfo,
        });

        return updatedProfileInfo;
      })
      .catch((err) => console.log(err));
  }

  async selectRequestTemplate(requestId) {
    let previousProfileInfo;
    if (!this.cacheService.getData().profileInfo) {
      previousProfileInfo = await this.getFirstProfile();
    } else {
      previousProfileInfo = this.cacheService.getData().profileInfo;
    }

    const updatedProfileInfo = {
      distributors: previousProfileInfo.distributors,
      currentProfile: {
        currentDistributor: previousProfileInfo.currentProfile.currentDistributor,
        currentTemplate: previousProfileInfo.currentProfile.templates.find((x) => x.requestId === requestId),
        templates: previousProfileInfo.currentProfile.templates,
      },
    };

    this.cacheService.removeEntry('profileInfo'); // must delete it first!
    this.cacheService.addData({
      profileInfo: updatedProfileInfo,
    });

    return updatedProfileInfo;
  }
}
